import Link from "next/link";
import { useForm } from "react-hook-form";
import { recordConversion, Conversion } from "utils/conversions";

export default function Newsletter() {
  const onSubmit = () => {
    recordConversion({
      send_to: Conversion.newsletter,
    });
    // @ts-ignore
    document.forms.newsletter.submit();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  return (
    <div className="bg-gray-800 mt-16 md:mt-0">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-1">
          <h2
            className="text-3xl font-semibold font-sans tracking-tight text-white sm:text-4xl"
            id="newsletter-headline"
          >
            Sign up for our newsletter
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
            Events, news and information not just for students but for the
            industry as a whole!
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          <form
            action="https://manage.kmail-lists.com/subscriptions/subscribe?a=XxnURE&g=TnB7rj"
            method="post"
            id="newsletter"
            name="mc-embedded-subscribe-form"
            className="sm:flex"
            target="_blank"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label htmlFor="emailAddress" className="sr-only">
              Email address
            </label>
            <input
              id="emailAddress"
              name="EMAIL"
              type="email"
              autoComplete="email"
              required
              {...register("EMAIL", { required: true })}
              className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
              placeholder="Enter your email"
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_1aff8571a3cc20c570aa705a3_40529e9318"
                tabIndex={-1}
              />
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:shrink-0">
              <button
                type="submit"
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-brand-500"
              >
                Notify me
              </button>
            </div>
          </form>
          <p className="mt-3 text-sm text-gray-300">
            We care about the protection of your data. Read our{" "}
            <Link
              href="/privacy-policy/"
              className="text-white font-medium underline"
            >
              Privacy Policy.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
